export default {
    methods: {
        loadDoctorInfo: function(data, callback){
            this.$store.dispatch('getDoctorDetail', data)
            .then((rs) => {
                callback && callback(rs, null)
            }).catch((error) => {
                callback && callback(null, error)
            })
        },
        loadClinicList: function(data, callback){
            this.$store.dispatch('getClinicListByDoctor', data)
            .then((rs) => {
                callback && callback(rs, null)
            }).catch((error) => {
                callback && callback(null, error)
            })
        },
        getDoctorAskList: function(data, callback){
            this.$store.dispatch('getDoctorAskList', data)
            .then((rs) => {
                callback && callback(rs, null)
            }).catch((error) => {
                callback && callback(null, error)
            })
        },
        getDoctorNewsList: function(data, callback){
            this.$store.dispatch('getDoctorNewsList', data)
            .then((rs) => {
                callback && callback(rs, null)
            }).catch((error) => {
                callback && callback(null, error)
            })
        },
    },
}