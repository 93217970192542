<template>
    <div id="doctor-ask">
        <div class="ask-list">
            <div class="empty" v-if="!loading && !dataList.length">-- 已經全部加載 --</div>
            <div class="ask-item" v-for="(item, index) in dataList" :key="index">
                <span class="avatar"></span>
                <div class="col">
                    <div class="row">
                        <span class="gender" v-text="item.gender == 1 ? '男' : '女' "></span>
                        <span class="age" v-text="`${item.age}歲`"></span>
                    </div>
                    <div class="row">
                        <router-link class="category" :to="`/ask/list?IllCate=${item.illnesscategoryid}`" v-text="`#${item.illnesscategorycode}`"></router-link>
                        <h2 class="title">
                            <router-link :to="`/ask/detail/${item.askid}`" v-text="item.title"></router-link>
                        </h2>
                    </div>
                    <div class="row">
                        <router-link class="msg" :to="`/ask/detail/${item.askid}`">
                            <img class="icon" src="../../../assets/imgs/pc/img_huifu@2x.png" alt="">
                            <span class="msg-count" v-text="`${item.answerList.length}條回復`"></span>
                        </router-link>
                        <span class="create-time" v-text="item.createdtime"></span>
                    </div>
                </div>
            </div>
        </div>
        <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
        <loading v-if="loading" />
    </div>
</template>

<script>
    import Loading from '@/layouts/Loading.vue'
    import SplitPage from '@/layouts/SplitPage.vue'
	import doctor_detail from '@/views/mixins/doctor_detail'
	export default {
        mixins: [doctor_detail],
        props: {
            doctor: null,
        },
        components: {
            Loading,
            SplitPage,
        },
        data(){
            return {
                pageNo: 1,
                pageSize: 10,
                total: 0,
                visible: false,
                dataList: [],
                loading: true,
            }
        },
        mounted: function(){
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
            this.onRefresh()
        },
        methods: {
            onPageChanged: function(pageNo){
                var path = this.$route.fullPath
                var hash = this.$route.hash
                if(hash && path.indexOf(hash) != -1) {
                    path = path.replace(hash, '')
                }
                this.$router.push({path:  path+'#page='+pageNo},(e)=>{})
            },
            onRefresh: function(){
                if(this.total > 0 && this.pageNo > 0) {
                    var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                    if(maxPage > 0 && maxPage < this.pageNo) {
                        var path = this.$route.fullPath
                        var hash = this.$route.hash
                        if(hash && path.indexOf(hash) != -1) {
                            path = path.replace(hash, '')
                        }
                        this.$router.push({path: `${path}#page=1`},(e)=>{})
                        return
                    }
                }
                this.loading = true
                this.getDoctorAskList({
                    doctorglobalid: this.doctor.globaluserid,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                }, (data, error)=>{
                    this.loading = false
                    if(data) {
                        this.total = data.total
                        this.dataList = data.list
                        this.visible = this.total ? true : false

                        var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                        if(maxPage > 0 && maxPage < this.pageNo) {
                            var path = this.$route.fullPath
                            var hash = this.$route.hash
                            if(hash && path.indexOf(hash) != -1) {
                                path = path.replace(hash, '')
                            }
                            this.$router.push({path: `${path}#page=1`},(e)=>{})
                        }
                    } else {
                        if(error) {
                            this._toast.warning(this, error)
                        }
                    }
                })
            }
        },
        watch: {
            '$route.hash': function(v){
                var hash = this.$route.hash
                hash = hash.replace('#', '')
                var list = hash.split('&')
                var key = list[0].split('=')[0]
                var value = list[0].split('=')[1] / 1
                if(key == 'page') {
                    if(! isNaN(value)) {
                        this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                        this.visible = false
                    }
                }
                this.onRefresh()
            },
        }
    }
</script>

<style lang="scss" scoped>
    #doctor-ask {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        padding-bottom: 60px;
    }

    .ask-list {
        width: 100%;
        min-height: 855px;
        display: flex;
        flex-direction: column;
    }

    .ask-list > .ask-item {
        height: 95px;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 9px 0px 11px 0px;
        margin: 0px 16px;
        border-bottom: 1px solid #E8E8E8;
    }

    .ask-list > .empty {
        position: absolute;
        left: 50%;
        top: 50px;
        transform: translateX(-50%);
        font-size: 16px;
        color: #999;
        font-weight: 400;
    }

    .ask-list > .ask-item > .avatar {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
		background-image: url(../../../assets/imgs/pc/img_toux@2x.png);
    }

    .ask-list > .ask-item .col {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .ask-list > .ask-item .row {
        display: flex;
        flex-direction: row;
    }

    .ask-list > .ask-item .user-name,
    .ask-list > .ask-item .gender,
    .ask-list > .ask-item .age {
        color: #969696;
        font-size: 14px;
        line-height: 13px;
        font-weight: 400;
    }

    .ask-list > .ask-item .age {
        margin-left: 8px;
    }
    
    .ask-list > .ask-item .category,
    .ask-list > .ask-item .title {
        font-size: 18px;
        font-weight: bold;
        color: #242020;
        line-height: 18px;
        margin-top: 9px;
    }

    .ask-list > .ask-item .category {
        color: #FF7800;
        margin-right: 8px;
    }

    .ask-list > .ask-item .msg {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 20px;
        height: 15px;
        line-height: 15px;   
    }

    .ask-list > .ask-item .msg > .icon {
        width: 17px;
        height: 15px;
    }

    .ask-list > .ask-item .msg > .msg-count {
        margin-left: 12px;
        color: #008D98;
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
    }

    .ask-list > .ask-item .create-time {
        margin-top: 20px;
        line-height: 15px;
        color: #969696;
        font-size: 14px;
        font-weight: 400;
    }
</style>
